<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Information</h3>
        <!-- <span class="text-muted font-weight-bold font-size-sm mt-1" >Update your personal informaiton</span> -->
      </div>
      <div class="card-toolbar">
        <button
          v-if="canAccess('super-admin')"
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>

        <!-- <button type="reset" class="btn btn-secondary" @click="reset()">
          Reset
        </button> -->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Avatar</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div
                class="image-input-wrapper"
                :style="{ backgroundImage: `url(${photo})` }"
              ></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  @change="onFileChange($event)"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel"
                data-toggle="tooltip"
                title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="remove"
                data-toggle="tooltip"
                title="Remove avatar"
                @click="current_photo = null"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <span class="form-text text-muted"
              >Allowed file types: png, jpg, jpeg.</span
            >
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              v-model="name_input"
              class="form-control form-control-lg form-control-solid"
              type="text"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >User Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              v-model="user_name_input"
              class="form-control form-control-lg form-control-solid"
              type="text"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Contact Phone</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input
                v-model="phone_input"
                type="number"
                class="form-control form-control-lg form-control-solid"
                placeholder="Phone"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Email Address</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                v-model="email_input"
                type="email"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-lock"></i>
                </span>
              </div>
              <input
                v-model="password_input"
                type="password"
                class="form-control form-control-lg form-control-solid"
                placeholder="Enter password"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Confirm Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-lock"></i>
                </span>
              </div>
              <input
                v-model="confirm_password_input"
                type="password"
                class="form-control form-control-lg form-control-solid"
                placeholder="Enter password"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Verified</label
          >
          <div class="col-lg-9 col-xl-6">
            <v-switch
              class="mt-0 pt-0 ml-2"
              v-model="verified_input"
              :label="`${verified_input.toString().toUpperCase()}`"
            ></v-switch>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
export default {
  name: "Information",
  props: ["data_user"],
  data() {
    return {
      current_photo: null,
      email_input: "",
      phone_input: "",
      user_name_input: "",
      name_input: "",
      password_input: "",
      confirm_password_input: "",
      verified_input: false,
    };
  },
  computed: {
    default_photo: {
      get() {
        return window.location.origin + "/media/users/blank.png";
      },
    },
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    name_input: function (val) {
      this.$emit("setDataForm", { filed: "name", value: val });
    },
    email_input: function (val) {
      this.$emit("setDataForm", { filed: "email", value: val });
    },
    phone_input: function (val) {
      this.$emit("setDataForm", { filed: "phone", value: val });
    },
    user_name_input: function (val) {
      this.$emit("setDataForm", { filed: "user_name", value: val });
    },
    password_input: function (val) {
      this.$emit("setDataForm", { filed: "password", value: val });
    },
    confirm_password_input: function (val) {
      this.$emit("setDataForm", { filed: "confirm_password", value: val });
    },
    verified_input: function (val) {
      this.$emit("setDataForm", { filed: "verified", value: val });
    },
    data_user: function (val) {
      if (val != undefined) {
        this.setInputData();
      }
    },
  },
  created() {
    if (this.data_user != undefined) {
      this.setInputData();
    }
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    setInputData() {
      this.name_input = this.data_user.name;
      this.email_input = this.data_user.email;
      this.phone_input = this.data_user.phone;
      this.user_name_input = this.data_user.user_name;
      this.verified_input = this.data_user.phone_verified == 1 ? true : false;
    },
    save() {
      this.$emit("submitForm");
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
};
</script>
