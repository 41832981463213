<template>
  <div>
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            Lịch sử thanh toán
          </h3>
        </div>
      </div>
      <!--end::Header-->
      <div class="card card-custom gutter-b">
        <!--begin::Body-->
        <div class="card-body py-0">
          <!--begin::Table-->
          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_2"
            >
              <thead>
                <tr class="text-uppercase">
                  <th class="pl-0" style="min-width: 100px">
                    <span class="text-primary">#</span>
                  </th>
                  <th>
                    <span class="text-primary">Chương trình</span>
                  </th>
                  <th>
                    <span class="text-primary">Price & discount</span>
                  </th>
                  <th>
                    <span class="text-primary">Coupon</span>
                  </th>
                  <th>
                    <span class="text-primary">Discount</span>
                  </th>
                  <th>
                    <span class="text-primary">Ngày tạo đơn</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(order, i) in payment_orders">
                  <tr :key="i">
                    <td class="pl-0">
                      <a
                        href="#"
                        class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                        >{{ i + 1 }}</a
                      >
                    </td>
                    <td>
                      <template v-if="order.programme !== null">
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          style="max-width: 250px"
                        >
                          - {{ order.programme.vi }}
                        </p>
                      </template>
                      <template v-for="(item, iItem) in order.items">
                        <p
                          :key="iItem"
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          style="max-width: 250px"
                        >
                          - {{ item.name.vi }}
                        </p>
                      </template>
                    </td>
                    <td>
                      <p class="mb-0">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Fee:
                        </span>
                        <span
                          class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                          >{{ order.fee | formatNumber }}</span
                        >
                      </p>
                      <p class="mb-0">
                        <span
                          class="text-dark-75 font-weight-bolder font-size-lg"
                          >Fee after discount:
                        </span>
                        <span
                          class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                          >{{ order.fee_after_discount | formatNumber }}</span
                        >
                      </p>
                    </td>
                    <td>
                      <template v-if="order.coupon">
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Code:
                          </span>
                          <span
                            class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                            >{{ order.coupon.code }}</span
                          >
                        </p>
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Discount amount:
                          </span>
                          <span
                            class="text-dark-75 font-size-lg blue--text text--lighten-1"
                            v-if="order.coupon.discount_type === 1"
                            >{{
                              order.coupon.discount_amount | formatNumber
                            }}
                            VND</span
                          >
                          <span
                            class="text-dark-75 font-size-lg amber--text text--lighten-1"
                            v-if="order.coupon.discount_type === 2"
                            >{{
                              order.coupon.discount_amount | formatNumber
                            }}
                            %</span
                          >
                        </p>
                      </template>
                    </td>
                    <td>
                      <template v-if="order.discount">
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Name:
                          </span>
                          <span
                            class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                            >{{ order.discount.name }}</span
                          >
                        </p>
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Discount amount:
                          </span>
                          <span
                            class="text-dark-75 font-size-lg blue--text text--lighten-1"
                            v-if="order.discount.type === 1"
                            >{{
                              order.discount.amount | formatNumber
                            }}
                            VND</span
                          >
                          <span
                            class="text-dark-75 font-size-lg amber--text text--lighten-1"
                            v-if="order.discount.type === 2"
                            >{{ order.discount.amount | formatNumber }} %</span
                          >
                        </p>
                      </template>
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder font-size-lg"
                        >{{
                          order.created_at | convertTimestampToDMY_HMS
                        }}</span
                      >
                    </td>
                  </tr>
                </template>
                <template v-if="!payment_orders.length">
                  <tr>
                    <td style="text-align: center" colspan="6">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >No data</span
                      >
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentHistory",
  props: {
    payment_orders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped></style>
