<template>
  <v-app>
    <div style="background-color: rgb(243 246 249)">
      <h1
        class="mt-5 mb-5"
        style="color: #3f4254"
        v-if="student_id == undefined"
      >
        Add Student
      </h1>
      <h1 class="mt-5 mb-5" style="color: #3f4254" v-else>Edit Student</h1>
    </div>
    <div class="d-flex flex-row">
      <div
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
        id="kt_profile_aside"
      >
        <div class="card card-custom card-stretch">
          <div class="card-body pt-4">
            <div class="d-flex align-items-center">
              <div
                class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
              >
                <div
                  class="symbol-label"
                  :style="{
                    'background-image': `url(${
                      location_origin + '/' + avatar
                    })`,
                  }"
                ></div>
                <i class="symbol-badge bg-success"></i>
              </div>
              <div>
                <a
                  class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                  >{{ name }}</a
                >
                <div class="text-muted">{{ email }}</div>
              </div>
            </div>
            <!--end::User-->
            <!--begin::Contact-->
            <div class="py-9">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="font-weight-bold mr-2">User Name:</span>
                <a class="text-muted text-hover-primary">{{ user_name }}</a>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">{{ phone }}</span>
              </div>
            </div>
            <!--end::Contact-->
            <!--begin::Nav-->
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
              role="tablist"
            >
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4 active"
                  @click="setActiveTab"
                  data-tab="0"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/General/User.svg" />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg font-weight-bold"
                    >Personal Information</span
                  >
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  data-tab="1"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg
                        src="media/svg/icons/Communication/Shield-user.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg font-weight-bold"
                    >Assign Programme</span
                  >
                  <span class="navi-label">
                    <span
                      class="label label-light-danger label-rounded font-weight-bold"
                      >{{ programme_ids.length }}</span
                    >
                  </span>
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  data-tab="2"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2"></span>
                  <span class="navi-text font-size-lg font-weight-bold"
                    >Lịch sử thanh toán</span
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--begin::Content-->
      <div class="flex-row-fluid ml-lg-8">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <Information
              @setDataForm="setDataForm"
              :data_user="data_user"
              @submitForm="submit"
            ></Information>
          </b-tab>

          <b-tab>
            <AssignProgramme
              @setDataForm="setDataForm"
              :data_user="data_user"
              @submitForm="submit"
            ></AssignProgramme>
          </b-tab>

          <b-tab>
            <PaymentHistory :payment_orders="data_user.orders"></PaymentHistory>
          </b-tab>
        </b-tabs>
      </div>
      <!--end::Content-->
    </div>
  </v-app>
</template>

<script>
import Information from "@/view/pages/students/profile/Information";
import AssignProgramme from "@/view/pages/students/profile/AssignProgramme";
import ApiService from "@/service/api.service";
import PaymentHistory from "@/view/pages/students/profile/PaymentHistory";

export default {
  name: "StudentDetail",
  components: {
    PaymentHistory,
    Information,
    AssignProgramme,
  },
  props: ["student_id"],
  data() {
    return {
      tabIndex: 0,
      avatar: "media/users/blank.png",
      email: "",
      phone: "",
      user_name: "",
      name: "",
      password: "",
      verified: false,
      confirm_password: "",
      programme_ids: [],
      data_user: {},
    };
  },
  computed: {
    location_origin: {
      get() {
        return window.location.origin;
      },
    },
  },
  created() {
    this.setInputData();
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async setInputData() {
      if (this.student_id !== undefined) {
        let vm = this;
        await ApiService.get("prep-app/student/" + this.student_id).then(
          function (response) {
            if (response.status === 200) {
              vm.user_name = response.data.user_name;
              vm.name = response.data.name;
              vm.email = response.data.email;
              vm.phone = response.data.phone;
              vm.verified = response.data.phone_verified === 1;
              vm.data_user = response.data;
              vm.programme_ids = response.data.programmes.map((e) => {
                return e.id;
              });
            }
          }
        );
      }
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }
      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));
      // set current active tab
      target.classList.add("active");
    },
    setDataForm(data) {
      if (data.filed == "name") {
        this.name = data.value;
      } else if (data.filed == "user_name") {
        this.user_name = data.value;
      } else if (data.filed == "phone") {
        this.phone = data.value;
      } else if (data.filed == "email") {
        this.email = data.value;
      } else if (data.filed == "password") {
        this.password = data.value;
      } else if (data.filed == "confirm_password") {
        this.confirm_password = data.value;
      } else if (data.filed == "programme_ids") {
        this.programme_ids = data.value;
      } else if (data.filed == "verified") {
        this.verified = data.value;
      }
    },
    submit() {
      if (this.password !== this.confirm_password) {
        this.$toasted.error(
          "Your password and confirmation password do not match !!",
          { theme: "toasted-primary", position: "top-right", duration: 4000 }
        );
        return;
      }
      if (this.user_name === "" || this.user_name === undefined) {
        this.$toasted.error("User name is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (this.name === "" || this.name === undefined) {
        this.$toasted.error("Name is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (this.email === "" || this.email === undefined) {
        this.$toasted.error("Email is required !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      let formData = new FormData();
      if (this.user_name !== "") formData.append("username", this.user_name);
      if (this.name !== "") formData.append("name", this.name);
      if (this.email !== "") formData.append("email", this.email);
      if (this.phone !== "") formData.append("phone", this.phone);
      if (this.password !== "") formData.append("password", this.password);
      if (this.programme_ids.length)
        formData.append("programme_ids", this.programme_ids);
      formData.append("verified", this.verified === false ? 0 : 1);
      this.$emit("submitForm", formData);
    },
  },
};
</script>
