var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading',{attrs:{"active":_vm.is_call_api},on:{"update:active":function($event){_vm.is_call_api=$event}}}),_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header py-3"},[_vm._m(0),_c('div',{staticClass:"card-toolbar"},[(_vm.canAccess('super-admin'))?_c('button',{ref:"kt_save_changes",staticClass:"btn btn-success mr-2",attrs:{"type":"reset"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save Changes ")]):_vm._e()])]),_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header border-0 py-5"},[(_vm.canAccess('super-admin'))?_c('div',{staticClass:"card-toolbar"},[_c('a',{staticClass:"btn btn-info font-weight-bolder font-size-sm",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openDialogAddProgramme()}}},[_vm._v(" Add Programme ")])]):_vm._e()]),_c('div',{staticClass:"card-body py-0"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-head-custom table-vertical-center",attrs:{"id":"kt_advance_table_widget_2"}},[_vm._m(1),_c('tbody',[_vm._l((_vm.programmes),function(programme,i){return [_c('tr',{key:i},[_c('td',{staticClass:"pl-0"},[_c('a',{staticClass:"text-dark-75 font-weight-bolder text-hover-primary font-size-lg",attrs:{"href":"#"}},[_vm._v(_vm._s(i + 1))])]),_c('td',[(programme.cover)?[_c('v-img',{staticClass:"rounded-lg",attrs:{"src":programme.cover.vi,"max-width":"100","max-height":"70"}})]:_vm._e()],2),_c('td',[(programme.name)?[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(_vm._s(programme.name.vi))])]:_vm._e()],2),_c('td',[(programme.assigner)?[(programme.is_assigned === 0)?_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg teal--text text--accent-4"},[_vm._v("Hệ thống")]):_vm._e(),_c('router-link',{attrs:{"to":{
                            name: 'UserEdit',
                            params: { id: programme.assigner.id },
                          },"target":"_blank"}},[(programme.is_assigned === 1)?_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg light-blue--text text--accent-4"},[_vm._v(_vm._s(programme.assigner.name))]):_vm._e()])]:[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg teal--text text--accent-4"},[_vm._v("Hệ thống")])]],2),_c('td')])]}),(!_vm.programmes.length)?[_vm._m(2)]:_vm._e()],2)])])])])]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"1000px","scrollable":""},model:{value:(_vm.dialogAddProgramme),callback:function ($$v) {_vm.dialogAddProgramme=$$v},expression:"dialogAddProgramme"}},[_c('v-card',[_c('v-card-title',[_c('v-col',{attrs:{"cols":"11"}},[_c('span',{staticClass:"headline"},[_vm._v("Add Programmee")])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"right":""},on:{"click":function($event){_vm.dialogAddProgramme = false}}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('label',{staticStyle:{"font-size":"16px"}},[_vm._v("Programme")]),_c('v-autocomplete',{attrs:{"items":_vm.programme_tags,"filled":"","chips":"","color":"blue-grey lighten-2","item-text":"name.vi","item-value":"id","multiple":""},model:{value:(_vm.programme_ids),callback:function ($$v) {_vm.programme_ids=$$v},expression:"programme_ids"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('button',{staticClass:"btn btn-light-success text-uppercase mr-2",on:{"click":_vm.btnAddProgramme}},[_vm._v(" Add ")]),_c('button',{staticClass:"btn text-uppercase mr-2",staticStyle:{"color":"#f64e60"},on:{"click":function($event){_vm.dialogAddProgramme = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title align-items-start flex-column"},[_c('h3',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Assign Programme ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"text-uppercase"},[_c('th',{staticClass:"pl-0",staticStyle:{"min-width":"100px"}},[_c('span',{staticClass:"text-primary"},[_vm._v("#")])]),_c('th',{staticStyle:{"min-width":"120px"}},[_c('span',{staticClass:"text-primary"},[_vm._v("Cover")])]),_c('th',{staticStyle:{"min-width":"150px"}},[_c('span',{staticClass:"text-primary"},[_vm._v("Name")])]),_c('th',{staticStyle:{"min-width":"150px"}},[_c('span',{staticClass:"text-primary"},[_vm._v("Được gắn bởi")])]),_c('th',{staticStyle:{"min-width":"150px"}},[_c('span',{staticClass:"text-primary"},[_vm._v("Action")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":"4"}},[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v("No data")])])])
}]

export { render, staticRenderFns }