<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            Assign Programme
          </h3>
          <!-- <span class="text-muted font-weight-bold font-size-sm mt-1" >Update your personal informaiton</span> -->
        </div>
        <div class="card-toolbar">
          <button
            v-if="canAccess('super-admin')"
            type="reset"
            class="btn btn-success mr-2"
            @click="save()"
            ref="kt_save_changes"
          >
            Save Changes
          </button>

          <!-- <button type="reset" class="btn btn-secondary" @click="reset()">
            Reset
          </button> -->
        </div>
      </div>
      <!--end::Header-->
      <div class="card card-custom gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 py-5">
          <div class="card-toolbar" v-if="canAccess('super-admin')">
            <a
              href="#"
              class="btn btn-info font-weight-bolder font-size-sm"
              @click.prevent="openDialogAddProgramme()">
              Add Programme
            </a>
          </div>

        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body py-0">
          <!--begin::Table-->
          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_2"
            >
              <thead>
                <tr class="text-uppercase">
                  <th class="pl-0" style="min-width: 100px">
                    <span class="text-primary">#</span>
                  </th>
                  <th style="min-width: 120px">
                    <span class="text-primary">Cover</span>
                  </th>
                  <th style="min-width: 150px">
                    <span class="text-primary">Name</span>
                  </th>
                  <th style="min-width: 150px">
                    <span class="text-primary">Được gắn bởi</span>
                  </th>
                  <th style="min-width: 150px">
                    <span class="text-primary">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(programme, i) in programmes">
                  <tr :key="i">
                    <td class="pl-0">
                      <a
                        href="#"
                        class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                        >{{ i + 1 }}</a
                      >
                    </td>
                    <td>
                      <template v-if="programme.cover">
                        <v-img
                          :src="programme.cover.vi"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </template>
                    </td>
                    <td>
                      <template v-if="programme.name">
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{ programme.name.vi }}</span
                        >
                      </template>
                    </td>
                    <td>
                      <template v-if="programme.assigner">
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg teal--text text--accent-4"
                          v-if="programme.is_assigned === 0"
                          >Hệ thống</span
                        >
                        <router-link
                          :to="{
                            name: 'UserEdit',
                            params: { id: programme.assigner.id },
                          }"
                          target="_blank"
                        >
                          <span
                            class="text-dark-75 font-weight-bolder d-block font-size-lg light-blue--text text--accent-4"
                            v-if="programme.is_assigned === 1"
                            >{{ programme.assigner.name }}</span
                          >
                        </router-link>
                      </template>
                      <template v-else>
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg teal--text text--accent-4"
                          >Hệ thống</span
                        >
                      </template>
                    </td>
                    <td>
<!--                      <v-tooltip right>-->
<!--                        <template v-slot:activator="{ on, attrs }">-->
<!--                          <button-->
<!--                            class="btn btn-icon btn-light-danger btn-sm ml-2"-->
<!--                            v-bind="attrs"-->
<!--                            v-on="on"-->
<!--                            @click="btnLockProgramme(programme.id, i)"-->
<!--                          >-->
<!--                            <span class="svg-icon svg-icon-md svg-icon-primary">-->
<!--                              <v-icon color="">mdi-lock</v-icon>-->
<!--                            </span>-->
<!--                          </button>-->
<!--                        </template>-->
<!--                        <span>Lock</span>-->
<!--                      </v-tooltip>-->
                    </td>
                  </tr>
                </template>
                <template v-if="!programmes.length">
                  <tr>
                    <td style="text-align: center" colspan="4">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >No data</span
                      >
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
        </div>
        <!--end::Body-->
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogAddProgramme" max-width="1000px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Add Programmee</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAddProgramme = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label style="font-size: 16px">Programme</label>
                  <v-autocomplete
                    v-model="programme_ids"
                    :items="programme_tags"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    item-text="name.vi"
                    item-value="id"
                    multiple
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnAddProgramme"
            >
              Add
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogAddProgramme = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import Ls from "@/core/services/jwt.service";
export default {
  name: "AssignProgramme",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: ["data_user"],
  data() {
    return {
      programmes: [],
      programme_tags: [],
      programme_ids: null,
      is_call_api: false,
      dialogAddProgramme: false,
    };
  },
  watch: {
    data_user: function (val) {
      if (val.programmes != undefined) {
        this.setInputData();
      }
    },
  },
  created() {
    this.getAllProgramme();
    if (this.data_user.programmes != undefined) {
      this.setInputData();
    }
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    setInputData() {
      this.programmes = this.data_user.programmes;
      for (
        let indexProgramme = 0;
        indexProgramme < this.programmes.length;
        indexProgramme++
      ) {
        for (
          let indexTag = 0;
          indexTag < this.programme_tags.length;
          indexTag++
        ) {
          if (this.programme_tags[indexTag].id == this.programmes[indexProgramme].id) {
            this.programme_tags.splice(indexTag, 1);
          }
        }
      }
    },
    save() {
      this.$emit("submitForm");
    },
    async getAllProgramme() {
      let seft = this;
      await ApiService.get("prep-app/programme/limit").then(function (
        response
      ) {
        if (response.status == 200) {
          seft.programme_tags = response.data;
        }
      });
    },
    openDialogAddProgramme() {
      this.programme_ids = null;
      this.dialogAddProgramme = true;
    },
    btnAddProgramme() {
      if (this.data_user.programmes != undefined) {
        let vm = this;
        let assigner = JSON.parse(Ls.getUser());
        let data = {
          programme_ids: vm.programme_ids,
        };
        Swal.fire({
          title: "Are you sure you want to change?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, do it!",
        }).then((result) => {
          if (result.value) {
            vm.is_call_api = true;
            ApiService.post(
              "prep-app/student/" + this.data_user.id + "/assign",
              data
            )
              .then(function (res) {
                if (res.status == 202) {
                  vm.is_call_api = false;
                  for (
                    let indexTag = 0;
                    indexTag < vm.programme_tags.length;
                    indexTag++
                  ) {
                    for (
                      let indexProgrammeAdd = 0;
                      indexProgrammeAdd < vm.programme_ids.length;
                      indexProgrammeAdd++
                    ) {
                      if (
                        vm.programme_tags[indexTag].id ===
                        vm.programme_ids[indexProgrammeAdd]
                      ) {
                        vm.programmes.push({
                          id: vm.programme_tags[indexTag].id,
                          name: vm.programme_tags[indexTag].name,
                          cover: vm.programme_tags[indexTag].cover,
                          is_assigned: 1,
                          assigner: {
                            id: assigner.id,
                            name: assigner.name,
                            username: assigner.username,
                          },
                        });
                        // vm.programmes.push(vm.programme_tags[indexTag]);
                        vm.programme_tags.splice(indexTag, 1);
                      }
                    }
                  }
                  vm.$emit("setDataForm", {
                    filed: "programme_ids",
                    value: vm.programmes.map((e) => {
                      return e.id;
                    }),
                  });
                }
              })
              .catch(function (error) {
                vm.is_call_api = false;
                console.log(error);
              });
          }
        });

        this.programme_ids = [];
        this.dialogAddProgramme = false;
      } else {
        for (
          let indexTag = 0;
          indexTag < this.programme_tags.length;
          indexTag++
        ) {
          for (
            let indexProgrammeAdd = 0;
            indexProgrammeAdd < this.programme_ids.length;
            indexProgrammeAdd++
          ) {
            if (
              this.programme_tags[indexTag].id ===
              this.programme_ids[indexProgrammeAdd]
            ) {
              this.programmes.push(this.programme_tags[indexTag]);
              this.programme_tags.splice(indexTag, 1);
            }
          }
        }
        this.$emit("setDataForm", {
          filed: "programme_ids",
          value: this.programmes.map((e) => {
            return e.id;
          }),
        });
        this.programme_ids = [];
        this.dialogAddProgramme = false;
      }
    },
    btnLockProgramme(programme_id, indexProgramme) {
      if (this.data_user.programmes != undefined) {
        let vm = this;
        let data = {
          programme_id: programme_id,
        };
        Swal.fire({
          title: "Are you sure you want to lock?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, lock it!",
        }).then((result) => {
          if (result.value) {
            vm.is_call_api = true;
            ApiService.post(
              "prep-app/student/" + this.data_user.id + "/lock",
              data
            )
              .then(function (res) {
                if (res.status == 202) {
                  vm.is_call_api = false;
                  vm.programme_tags.push(vm.programmes[indexProgramme]);
                  vm.programmes.splice(indexProgramme, 1);
                  vm.$emit("setDataForm", {
                    filed: "programme_id",
                    value: vm.programmes.map((e) => {
                      return e.id;
                    }),
                  });
                }
              })
              .catch(function (error) {
                vm.is_call_api = false;
                console.log(error);
              });
          }
        });
      } else {
        this.programme_tags.push(this.programmes[indexProgramme]);
        this.programmes.splice(indexProgramme, 1);
        this.$emit("setDataForm", {
          filed: "programme_id",
          value: this.programmes.map((e) => {
            return e.id;
          }),
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
/* .text-primary {
  color: #3699FF !important;
} */
</style>
